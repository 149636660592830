html {
    overflow: hidden;
    font-family: montserrat;

    header {
        height: 3rem;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        border-bottom: 1px solid rgb(45, 45, 45);
        .logo {
            margin-left: 1rem;
            font-weight: 700;
        }
    }
    .p-card {
        margin-left: 25%;
        width: 40%;
        height: 5rem;
        color: white;
        font-size: 1rem;
        margin-top: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .5rem;
        position: absolute;
        border: 1px solid white;
        background-color: transparent;
    }

    .p-button {
        background-color: black;
        color: white;
        border: none;
        margin-right: 1rem;
    }
    .p-menu {
        right: 0rem !important;
        a {
            width: 100%;
            color: white;
            text-decoration: none;
            font-size: 1rem;
        }
    }

    @media (max-width: 768px) {
        header {
            height: 3rem;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: end;
            border-bottom: 1px solid rgb(45, 45, 45);
            overflow-x: hidden;
            
            a {
                color: white;
                text-decoration: none;
                font-size: .75rem;
                position: relative;
                right: 0.375rem;
            }
        }
        .p-card {
            margin-left: 0;
            width: 80%;
            height: 4.5rem;
            font-size: 0.8rem;
            padding: 1rem;
            position: absolute;
            top: 5rem;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
        }
    }








}


